.cl1{
    margin-left: 10px;
    font-family: 'Cosmica Bold';
    
}

.cl2{
    margin-left: 20px;
    font-family: 'Cosmica Bold';
}

.cl3{
    margin-left: 30px;
    font-family: 'Cosmica Bold';
    cursor: pointer;
}

.cl3:hover{
    opacity: 0.5;
}

.cl4{
    margin-left: 50px;
    
}