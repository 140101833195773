#outlined-multiline-static{
    height: 76px !important;
}

.img_nf{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .titulo{
        font-size: 20px;
    }
  
    .mini-titulo{
        font-size: 14px;
    }
    .hidemob{
        display: none;
    }

    .center.w50{
        width: 90vw;
    }

    .bt{
        font-size: 14px;
        width: 100%;
    }
    
}

.bgcolor_fore {
    background-color: #f0eddf;
}

#fore-root input {
  background-color: transparent;
  width: 100%;
  border-radius: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px;
  outline: 0px;
  margin-bottom: 0px;
  font-family: 'Space Grotesk', sans-serif;
}

#fore-root .MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6) !important;
}

#fore-root .MuiSelect-select {
  color: rgba(0, 0, 0, 0.6) !important;
}

#fore-root .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  color: rgba(0, 0, 0, 0.6) !important;
  background-color: #333 !important;
}

#fore-root .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  color: rgba(0, 0, 0, 0.6) !important;
}

#fore-root .MuiInputBase-input {
  height: auto !important;
}

#fore-root .MuiOutlinedInput-inputMultiline{
  min-height: 0px !important;
}

#fore-root .MuiSelect-icon{
  fill: black !important
}

#fore-root .MuiOutlinedInput-notchedOutline{
  border-color: #6468EF !important;
}

#fore-root ::selection {
  color: inherit !important;
  background: #6468EF !important;
}

#fore-root {
  color: rgba(0, 0, 0, 0.87) !important;
}

#fore-root .center {
  margin-top: 40vh;
}

#fore-root .conteudo {
  background-color: #f0eddf;
  min-height: 99vh;
}

#fore-root {
  font-family: 'Space Grotesk', sans-serif;
}

#fore-root {
  font-size: 16px;
}

.progress-wrapper {
  margin-top: 20px;
  display: flex;
  position: relative;
  height: 30px;
}