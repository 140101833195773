.label-report{
    
    padding: 20px;
    width: 100%;
}

.label-report-linha{
    
    padding: 10px;
    width: 100%;
}

.card-light{
    opacity: 0.5;
}

.card-mes{
    background-color: blueviolet !important;
    color: white !important;
}

.line-graph{
    height: 400px;
    
}

.ml10{
    margin-left: 10px;
}

.mr10{
    margin-right: 10px;
}

.mr20{
    margin-right: 20px;
}

.mr30{
    margin-right: 30px;
}

.ml20{
    margin-left: 20px;
}
.ml30{
    margin-left: 30px;
}

.sub-titulo.mt30{
    padding-top: 10px;
    padding-bottom: 10px;
}