#outlined-multiline-static{
    height: 76px !important;
}

.img_nf{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .titulo{
        font-size: 20px;
    }
  
    .mini-titulo{
        font-size: 14px;
    }
    .hidemob{
        display: none;
    }

    .center.w50{
        width: 90vw;
    }

    .bt{
        font-size: 14px;
        width: 100%;
    }
    
}