.flex {
    display:flex;
}
.flexbox {
    display:flex;
    justify-content: space-between;
    width: 100%;
    max-width: 768px;
    
    overflow: hidden;
    margin: 0 auto;
    padding: 15px;
    
}

.board{
display: flex;
flex-direction:column;
min-height: 70vh;
width: 100%;
border-radius: 5px;
background-color: rgba(166, 166, 166, 0.05);
margin-top: 5px;
}

.card{
    width: 100%;
    
    background-color: #444;
    margin-bottom: 2px;
    color: white;
    height: 45px;
    cursor:move;
}

.card-version{
    width: 100%;
    padding: 2px  25px;
    background-color: #333;
    margin-bottom: 2px;
    color: white;
    height: 45px;
}

.header-budget{
    padding: 20px;
    font-size: 14px;
}

.nome-budget{
    margin-top: 4px;
    max-width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nome-versao{
    margin-top: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.icone-budget{
    float: right;
    margin-top: -17px;
    cursor: pointer;
}

.abre{
    height: 300px;
    background-color: white;
    width: 100%;
}

.barra{
    width: 100%;
    padding: 10px;
}

.pie{
   
    height: 18vw;
    color: #333 !important;
   
}

.pie-card{
    
    height: 20vw;
    color: #333 !important;
   
}

.legenda-pie{
    width: 100%;
    text-align: center;
    margin-top: -9.5vw;
    line-height: 18px;
    height: 12vw;
}

.legenda-pie-card{
    width: 100%;
    text-align: center;
    margin-top: -10.5vw;
    line-height: 18px;
    height: 12vw;
}

.legenda-pie.duplo{
    width: 100%;
    text-align: center;
    margin-top: -10vw;
    line-height: 18px;
    height: 150px;
}

.icon-right{
    text-align: right;
    height: 20px !important;
}

.nao_aprovado{
    text-decoration: underline;
    font-size: 10px;
    cursor: pointer;
    color: darkgoldenrod;
  
}

.menu-budget{
    position: absolute;
    width: 22%;
    opacity: 0;
    transition: 0.4s;
    background-color: #333;
    transition: 0.2s;
    z-index: 10;
}

.menu-budget:hover{
    opacity: 1;
}

.valor-budget{
    width: 100%;
   
    text-align: right;
    margin-top: -33px;
    
   
    height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 1;
  
}


.fc{
    color:#333 !important;
}


.ff{
    width: 100%;
    padding: 12px;
    padding-top: 14px;
    height: 36px;
    
  }

  .ff-per{
    width: 100%;
    padding: 12px;
    padding-top: 18px;
    height: 41px;
  }

.ff.c1{
    background-color: #333;
    height: 100%;
}
.ff-ro{
    width: 100%;
    padding: 12px;
    padding-top: 16px;
    height: 36px;
    height: 100%;
  }

.ff:hover{
    height: 36px;
    border: 1px solid #555;
    border-radius: 5px;
}

.ff-per:hover{
    border: 1px solid #555;
    border-radius: 5px;
}

.barra-valor{
    position: fixed;
    bottom: 0px;
    width: 100vw;
    left: 0px;
    z-index: 100;
    padding-left: 40px;
    padding-right: 40px;
    z-index: 100;
}

.barra-gradiente{
    height: 40px;
    background-image: linear-gradient(0deg,rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
}

.search-line {
    padding: 7px;
    height: 100%;
}

.line-bloco{
    cursor: pointer;
    border: 1px solid #333;
}

.line-bloco:hover{
    opacity: 0.9;
    border: 1px solid #555;
}

.dia-semana{
    width: 13vw;
    padding: 10px;
    
    font-size: 10px;

    
}

.box2{
    height: 200px;
}

.base-eventos{

    height: 180px;
    width: 100%;
}
.box-calendar{
    width: 13vw;
    border: 1px solid #444;
}

.box-calendar-off{
    width: 13vw;
    border: 1px solid #444;
}

.box-calendar-high{
    width: 13vw;
    border: 1px solid #f0f0f0;
}

.box-calendar:hover{
    border: 1px solid #888;
}
.calendar-mes{
    display: flex;
    justify-content: space-between;
    
}

.box-calendar{
    
}

.box-calendar.cor0{
    
    border: 1px solid #5f5f5f;
}

.box-calendar.cor1{
    
    border: 1px solid #A456FF;
}

.box-calendar.cor2{
    
    color: #333;
    border: 1px solid #A3D7C9;
}


.box-calendar.cor3{
    
    color: #333;
    border: 1px solid #F9D48E;
}

.box-calendar.cor4{
    
    color: #333;
    border: 1px solid #7FDCA4;
}


.box-calendar.cor5{
    
    border: 1px solid #A456FF;
}

.box-calendar.cor6{
    
    border: 1px solid orange;
}

.txt-dia{
    width: 100%;
    height: 90px;
    padding: 16px 10px;
    color: #f0f0f0;
}

.txt-dia.c2{
    
    color: #888;
}


.incluso{
    min-height: 500px;
}

.incluso:hover{
    border: 1px solid #f0f0f0;
}

.imgHost{
    
    overflow: hidden;
}

.imgGif{
    width: 100%;
    height: 6vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s;
    cursor: pointer;
}

.gs{
    filter: grayscale(100%);
    opacity: 0.7;
}

.imgGif:hover{
    transform: scale(1.2);
    filter: grayscale(0%);
}


.titulo-office{
    font-family: 'Cosmica Bold';
    font-weight: bold;
    font-size: 25px;
    padding-top: 35px;
    padding-left: 53px;
}

.bloco_magma{
    margin-bottom: -5px;
    border-top: 1px solid black;
    margin-top: 20px;
}

.bloco_liquor{
    margin-bottom: -5px;
    border-top: 1px solid white;
    margin-top: 20px;
}



.bloco_magma_entregas{
    height: 200px;
}
.up{
    text-transform: uppercase;
}

.magma-inv{
font-size: 14px;
}

.bloco_magma2{
    border-top: 1px solid black;
    margin-top: 20px;
    margin-bottom: 5px;
}

.bloco_liquor2{
    border-top: 1px solid white;
    margin-top: 20px;
    margin-bottom: 5px;
}

.incluso-magma{
    padding: 30px;
    margin-top: 130px;
}

.box-extras-assina-magma{
    width: 95%;
}

.evento{
    width: 100%;
    padding: 7px;
    color:black;
    margin:2px;
}

.evento-print{
    width: 100%;
    padding: 4px;
    color:black;
    margin:3px;
    font-size: 10px;
}

.color-box{
    width: 20px;
    height: 20px;
    border: 1px solid white;
    margin-right: 20px;
    padding: 5px;
}

.box-marker{
    width: 8px;
    height: 8px;
    background-color: white;
}

.divider-evento{
    width: 200px;
    height: 1px;
    background-color: #333;
    margin:20px
}

.logo-calendar-print{
    height: 60px;
    width: 240px;
    margin-left: -20px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: fill;
    background-position-x: left;
    background-position-y:center;
  }

  .celula-tipo-cal{
     
      padding: 10px;
      width: 25rem;
      cursor: pointer;
  }
  
  .celula-tipo-cal:hover{
      background-color: #555;
  }

  .linha-nome-mes{
      
      padding: 5px;
      padding-left: 20px;
  }

.sticky2{
    background-image: -webkit-linear-gradient(top, rgba(35, 35, 35, 1),rgba(35,35,35,0) 100%, transparent 100%, transparent 0%);
    padding-top: 20px;
    height: 100px;
    z-index: 100;
}

.holdstick{
    padding-top: 20px;
    height: 100px;
}

.bt-save-office{
    position: fixed;
    top:0px;
    width: 20vw;
    right: 0px;
    cursor: pointer;
}

.bt-save-office:hover{
    opacity: 0.9;
}


.filmcard{
    margin: auto;
}

.ztop{
    z-index: 1000;
}

.bt.nano-titulo{
    font-size: 11px !important;
    text-align: left;
    padding-left: 10px;
}