.box-login{
    display: flex;
    flex-direction: column;
    width: 40vw;
    margin: auto;
    margin-top: -8vh;
  }
  
  .box-login input{
    height: 30px;
  }
  .conteudo-login{
      height: 40vh !important;
  }
  
  .underline{
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 800px) {
    .box-login{
      margin-top: 0vh;
      width: 80vw;
      
    }
  }