.page_card_pix{
    width: 390px;
    margin-left: 35vw;
    
}
.full-card{
    width: 390px;
    margin: auto;
    z-index: 100;
    position: relative;
    margin-top: 50vh;
    padding-top: 15vh;
    background: rgb(45,45,45);
    background: linear-gradient(0deg, rgba(10,10,10,1) 62%, rgba(10,10,10,0) 100%);
    min-height: 50vh;
    
}

.line-sub-verba{
    
    padding-top: 5px;
}

.verba-sub{
    border-left: solid 10px black;
}
.recibos{
    position: relative;
    background-color: rgba(10,10,10,1);
    z-index: 100;
}
.ls_card{
    width: 350px;
    height: 58vh;
    margin-top: 100px;
    margin-left: 20px;
    border-radius: 20px;
    background: rgb(45,45,45);
    background: linear-gradient(123deg, rgba(5,5,5,1) 0%, rgba(45,45,45,1) 48%, rgba(10,10,10,1) 100%);
    display: flex;
    justify-content: space-between;
    position: fixed;
    top:20px;
    z-index: 50;;
    
}

.hold_card{
    margin: auto;
    text-align: center;
}
.chip{
    zoom: 0.6;
    margin: auto;
    margin-top: -40px;
}

.logo_cartao{
   
    width: 200px;
    transform: rotate(90deg);
    margin: auto;
    margin-top: 200px;
    margin-left: 40px;
    fill:#999
}

.senha{
    border-radius: 10px;
    border: 1px #555 solid;
    width: 50px;
    height: 70px;
    padding: 19px;
    font-size: large;
    text-align: center;
}

.senha2{
    border-radius: 10px;
    border: 1px #555 solid;
    width: 50px;
    height: 70px;
    padding-top: 25px;
    font-size: 20px;
    text-align: center;
}

.senhas{
    
    margin-top: 30px;
    justify-content: space-between;
}

.pagar{
    min-height: 40vh;
}

@media only screen and (max-width: 600px) {
.page_card_pix{
    width: 390px;
    margin-left: 0vw;
    
}
.full-card{
    width: 390px;
    margin: auto;
    z-index: 100;
    position: relative;
    margin-top: 55vh;
    padding-top: 15vh;
    background: rgb(45,45,45);
    background: linear-gradient(0deg, rgba(10,10,10,1) 62%, rgba(10,10,10,0) 100%);
    min-height: 50vh;
    
}

.recibos{
    position: relative;
    background-color: rgba(10,10,10,1);
    z-index: 100;
}
.ls_card{
    width: 350px;
    height: 65vh;
    margin-top: 1px;
    margin-left: 20px;
    border-radius: 20px;
    background: rgb(45,45,45);
    background: linear-gradient(123deg, rgba(5,5,5,1) 0%, rgba(45,45,45,1) 48%, rgba(10,10,10,1) 100%);
    display: flex;
    justify-content: space-between;
    position: fixed;
    top:20px;
    z-index: 50;;
    
}

.hold_card{
    margin: auto;
    text-align: center;
}
.chip{
    zoom: 0.6;
    margin: auto;
    margin-top: -40px;
}

.logo_cartao{
   
    width: 200px;
    transform: rotate(90deg);
    margin: auto;
    margin-top: 200px;
    margin-left: 40px;
    fill:#999
}

.senha{
    border-radius: 10px;
    border: 1px #555 solid;
    width: 50px;
    height: 70px;
    padding: 19px;
    font-size: large;
    text-align: center;
}

.senha2{
    border-radius: 10px;
    border: 1px #555 solid;
    width: 50px;
    height: 70px;
    padding-top: 25px;
    font-size: 20px;
    text-align: center;
}

.senhas{
    
    margin-top: 30px;
    justify-content: space-between;
}

.pagar{
    min-height: 40vh;
}
}

