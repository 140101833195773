.bar-status{
    width: 40%;
    height: 5px;
}

.icons-line{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
}

.eli{

white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky2{
  background-image: -webkit-linear-gradient(top, rgba(35, 35, 35, 1),rgba(35,35,35,0) 100%, transparent 100%, transparent 0%);
  padding-top: 20px;
  height: 100px;
  z-index: 50;
}

.holdstick{
  padding-top: 20px;
  height: 100px;
}

.cor_perf_1{
  background-color: #3ca766;
}

.cor_perf_2{
  background-color:#c75d3f;
}

.recibo{
  background-color: white;
  color: #000;
  font-family: 'Times New Roman', Times, serif;
  font-size: 14px;
  line-height: 30px;
  padding: 20px;
  padding-bottom: 45px;
}

.assinar{
  width: 100%;
  border: 1px #999;
  border-color: #999;
  border-style: dashed;
  padding: 15px;
  text-align: center;
}

.assinado{

  width: 100%;
  border-bottom: 1px solid;
  font-family: 'Mayestica';
  font-size: 50px;
  color: #666;
  padding: 15px;
  text-align: center;

}

.assinado.sm{
  font-size: 40px;
}

.concluido{
  font-size: 30px;
  text-align: center;
  padding: 30px;
}