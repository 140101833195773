*{
  box-sizing: border-box;
  margin: 0px;
  margin-top: 0px;
  
}

a{
  text-decoration: none;
  color:white;
  transition: 0.3s;
}


a:hover{
  color:#888 !important;
}


.clear{
  clear:both
}

.iframe_ls{
  width: 100vw;
  height: 100vh;
  border: 0;
}

.caps{
  text-transform: capitalize;
}

@font-face {
  font-family: 'Cosmica Bold';
  src: url('./fonts/CosmicaBold-Regular.woff2') format('woff2'),
      url('./fonts/CosmicaBold-Regular.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cosmica';
  src: url('./fonts/CosmicaLight-Regular.woff2') format('woff2'),
      url('./fonts/CosmicaLight-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Mayestica';
  src: url('./fonts/Mayestica.woff2') format('woff2'),
      url('./fonts/Mayestica.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


html, body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #111111;
  background: linear-gradient(110deg, #323232 0%, #040404 70%);
  min-height: 100vh;
  font-family: 'Cosmica';
  font-size: 13px;
  font-weight: 400;
  color:#cfcfcf;
  
}



.b{
  font-family: 'Cosmica Bold';
  font-weight: bold;
  
}

.titulo-huge{
  
  font-size: 50px;
  font-weight: 200;
}
.titulo{
  
  font-size: 50px;
  font-weight: 200;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
    overflow: hidden;
    padding-top: 2px;
    text-overflow: ellipsis;
}

.titulo-med{
  font-size: 40px;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
    overflow: hidden;
    padding-top: 2px;
    text-overflow: ellipsis;
}


.sub-titulo{
  font-size: 30px;
  font-weight: 300;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
    overflow: hidden;
    padding-top: 2px;
    text-overflow: ellipsis;
  
}

.sub-titulo.pdf{
  padding-top: 10px;
  font-size: 25px;
}
.mini-titulo{
  font-size: 20px;
  font-weight: 250;
  white-space: nowrap;
    overflow: hidden;
    padding-top: 2px;
    text-overflow: ellipsis;
 
}

.micro-titulo{
  font-size: 16px;
  font-weight: 250;
   white-space: nowrap;
    overflow: hidden;
    padding-top: 2px;
    text-overflow: ellipsis;
 
}

.nano-titulo{
  font-size: 10px;
}


.conteudo{   
  width: 100%;
  padding: 40px;
  min-height: 90vh;
}

.conteudo_free{   
  width: 100%;
  padding: 40px;
}

.conteudo.footer{   
  
  min-height: 1vh;
}

.header{
  height: 100px;
}

.listaLinks{
  opacity: 0;
  transition: all 1s ease-out;
}
.show{

  opacity: 1;
  
}

.hide{

  opacity: 0;
  
}
.opacity-30{
  opacity: 0.3;
}

.block{
  display: block;
}
.none{
  display: none;
}

.ma{

  margin:auto;
}

.l{
  float: left;
}

.r{
  float: right;
}


.f{
  display:flex;
}

.fj{
  justify-content:flex-end;
  padding-right: 40px;
  padding-left: 40px;
}

.va{
  
  align-self: flex-start;
}

.color_0{
  color: whitesmoke;
}

.bgcolor_0{
  background: whitesmoke;
  color: #3A3A3A;
}

.color_1{
  color: #A456FF;
}

.bgcolor_1{
  background: #A456FF;
  color: whitesmoke;
}

.bgcolor_1dark{
  background: #7337b8;
  color: whitesmoke;
  
}

.bgcolor_1d{
  background: #4d4d4d;
  color: whitesmoke;
  
}

.bgcolor_off{
  background: #4d4d4d;
  color: rgb(161, 161, 161);
  
}

.color_2{
  color: #7FDCA4;
  
}

.bgcolor_2{
  background: #7bca9b;
  color: #3A3A3A;
}

.bottom_1{
  margin-bottom: 2px;
}

.bottom_2{
  border-bottom: 5px solid #7bca9b;
}

.bottom_4{
  border-bottom: 5px solid #F9D48E;
}

.color_3{
  color: #A3D7C9;
  
}

.bgcolor_3{
  background: #91c2b5;
  color: #3A3A3A;
}
.bgcolor_3d{
  background: #73ac9d;
  color: #3A3A3A;
}

.color_4{
  color: #e8b77c;
  
}

.bgcolor_4{
  background: #e8b77c;
  color: #3A3A3A;
}

.color_5{
  color: #F9A58E;
  
}

.bgcolor_5{
  background: #F9A58E;
  color: #3A3A3A;
}

.bgcolor_5s{
  background: #e65229;
  color: #3A3A3A;
}

.bgcolor_6{
  background: #2A2A2A;
}

.bgcolor_7{
  background: #3A3A3A;
}

.bgcolor_8{
  background: #575757;
}

.bgcolor_9{
  background: #787878;
}

.bgcolor_10{
  background: #444;
}
.bgcolor_blk{
  background: black;
}

.bgcolor_11{
  background: #744aa5;
  color: whitesmoke;
  
}

.color_8{
  color: #555;
}


.color_9{
  color: #333;
}

.color_7{
  color: #777;
}



.px{
  width: 100%;
  height: 1px;
}

.p2{
  padding: 10px;
}

.p3{
  padding: 15px;
}


div{
  box-sizing: border-box;
  outline: 0px;
}
::selection {
  color: white;
  background: #A456FF;
}

.wp10{
  width: 10%;
}
.wp15{
  width: 15%;
}
.wp20{
  width: 20%;
}
.wp30{
  width: 30%;
}
.wp40{
  width: 40%;
}
.wp50{
  width: 50%;
}
.wp60{
  width: 60%;
}
.wp70{
  width: 70%;
}
.wp80{
  width: 80%;
}
.wp90{
  width: 90%;
}
.wp95{
  width: 95%;
}
.wp100{
  width: 100%;
}

.h5{
  height: 5px;
}
.h10{
  height: 10px;
}
.h15{
  height: 15px;
}
.h20{
  height: 20px;
}
.h30{
  height: 30px;
}
.h40{
  height: 40px;
}
.h50{
  height: 50px;
}
.h60{
  height: 60px;
}
.h70{
  height: 70px;
}
.h80{
  height: 80px;
}
.h90{
  height: 90px;
}
.h100{
  height: 100px;
}

.lh20{
  line-height: 16px;
}

.w5{
  width: 5vw;
}
.w10{
  width: 10vw;
}
.w15{
  width: 15vw;
}
.w20{
  width: 20vw;
}
.w30{
  width: 30vw;
}
.w40{
  width: 40vw;
}
.w50{
  width: 50vw;
}
.w60{
  width: 60vw;
}
.w70{
  width: 70vw;
}
.w80{
  width: 80vw;
}
.w85{
  width: 85vw;
}

.w90{
  width: 90vw;
}

.w100{
  width: 100vw;
}


.mb1{
  margin-bottom: 1px;
}

.mb5{
  margin-bottom: 5px;
}
.mb10{
  margin-bottom: 10px;
}
.mb15{
  margin-bottom: 15px;
}
.mb20{
  margin-bottom: 20px;
}
.mb30{
  margin-bottom: 30px;
}
.mb40{
  margin-bottom: 40px;
}
.mb50{
  margin-bottom: 50px;
}
.mb60{
  margin-bottom: 60px;
}
.mb70{
  margin-bottom: 70px;
}
.mb80{
  margin-bottom: 80px;
}
.mb90{
  margin-bottom: 90px;
}
.mb100{
  margin-bottom: 100px;
}



.mt1{
  margin-top: 1px;
} 
.mt2{
  margin-top: 2px;
}
.mt5{
  margin-top: 5px;
}
.mt10{
  margin-top: 10px;
}
.mt15{
  margin-top: 15px;
}
.mt20{
  margin-top: 20px;
}
.mt25{
  margin-top: 25px;
}
.mt30{
  margin-top: 30px;
}
.mt35{
  margin-top: 35px;
}
.mt40{
  margin-top: 40px;
}
.mt45{
  margin-top: 45px;
}
.mt50{
  margin-top: 50px;
}
.mt60{
  margin-top: 60px;
}
.mt70{
  margin-top: 70px;
}
.mt80{
  margin-top: 80px;
}
.mt90{
  margin-top: 90px;
}
.mt100{
  margin-top: 100px;
}

.mr5{
  margin-right: 5px;
}

.ml5{
  margin-left: 5px;
}



.pl10{
  padding-left: 10px;
}

.pl20{
  padding-left: 20px;
}

.pd2{
  padding: 2px;
}

.pd5{
  padding: 5px;
}
.pd10{
  padding: 10px;
}
.pd15{
  padding: 15px;
}
.pd20{
  padding: 20px;
}
.pd30{
  padding: 30px;
}
.pd40{
  padding: 40px;
}
.pd50{
  padding: 50px;
}
.pd60{
  padding: 60px;
}
.pd70{
  padding: 70px;
}
.pd80{
  padding: 80px;
}
.pd90{
  padding: 90px;
}
.pd100{
  padding: 100px;
}

.vertical-center {
  margin: auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.br5{
  border-radius: 5px;
}

.br10{
  border-radius: 10px;
}

.br15{
  border-radius: 15px;
}

.tr{
  width: 100%;
  text-align: right;
}

.tc{
  width: 100%;
  text-align: center;
}

.bt{
  height: 40px;
  padding-top: 14px;
  font-weight: 300;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.5s;
}


.bt.h{
  margin-top:6px;
  
}
.bt.dialog{
  float: right;
  
  margin-left: 30px;
}
.bt.large{
  padding-top: 18px;
  height: 50px;
}

.bt.form-large{
  padding-top: 16px;
  height: 45px;

}
.bt:hover{
  filter: brightness(1.2);
}

input{
  
  background-color: rgba(50, 50, 50,0.9);
  width: 100%;
  border-radius: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  color: whitesmoke !important;
  font-size: 13px;
  outline: 0px;
  margin-bottom: 15px;
}

.MuiInputLabel-root{
  color: whitesmoke !important;
}

.MuiSelect-select{
  color: whitesmoke !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  color: whitesmoke !important;
  background-color: #333 !important;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  color: whitesmoke !important;

}
.MuiInputBase-input {
  height: 1rem !important;
}

.MuiOutlinedInput-inputMultiline{
  min-height: 300px !important;
}

.MuiSelect-icon{
  fill: white !important
}

.MuiOutlinedInput-notchedOutline{
  border-color: rgba(255,255,255,0.2) !important;
}

.css-vubbuv{
  fill:white !important;
  margin-right: 7px;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: whitesmoke !important;
  opacity: 1; /* Firefox */
}

hr{
  border: 1px solid #333;
  margin-top: 50px;
  margin-bottom: 50px;

}

hr.small{
  margin-top: 20px;
  margin-bottom: 20px;
}

.cl{
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.cl:hover{
  opacity: 0.7;
}

.listLine{
  box-sizing: border-box;
  
  border-radius: 5px;
  height: 58px;
  margin-top:14px;
}

.listItem{
  padding: 13px;
  
}

  .label-container {
    font-size: 18px;
  }
  
  .line{
    cursor: pointer;
  }

  .line:hover{
    opacity: 0.8;
  }

  .img_user_large{

    border-radius: 50%;
    width: 300px;
    height: 300px;
    background-size: cover;
    margin: auto;
}

.img_user_micro{
    margin-left: 20px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-size: cover;

}

.img_user_micro.login{
  
  border: 5px solid #787878;
  width: 45px;
  height: 45px;
  cursor: pointer;
  transition: 0.3s;

}

.img_user_micro.login.msn{
  
  margin-left: 0px;

}

.img_user_micro.login:hover{
  border: 5px solid #A456FF;
}

.line-budget{
  font-size: 11px;
}

/* PRINT */

.conteudo.print{
  
  width: 100%;
  padding: 30px;
  min-height: 90vh;
 
}

.full{
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 50;
}

.menu{
  z-index: 55;

}

.center{
  margin: auto;
  margin-top: 45vh;

}

.logo{

  cursor: pointer;
  transition: 0.3s;
}
.logo:hover{
  opacity: 0.7;
}

.eli{

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
}


.progress-top{
  z-index: 250;
  opacity:1;  
  animation: flickerAnimation 1s infinite;
}



.x{
  padding-right: 10px;
  padding-top: 10px;
  cursor: pointer;
}

.x:hover{
  color:#73ac9d
}


@keyframes flickerAnimation { /* flame pulses */
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
}




@media only screen and (max-width: 1600px) {
  body {
    font-size: 11px;
  }
}


@media only screen and (max-width: 800px) {
  .menu{
    display: none !important;
  }

  .logo-landscape{
    margin-top: -33px !important;
}

}


