.menu{
    display: flex;
    justify-content: flex-end; 
    font-size: 14px;
}

.menu-item{
    margin-top: 35px;
    min-width: 150px;
    transition: 0.5s;
    
} 

.menu-item[disabled]{
    display: none;
}

a.active{
    font-weight: 800;
}


.menu-item:hover{
    color:white !important;
}

.logo-landscape{

    fill:white;
    margin-left: 38px;
    margin-top: -5px;
    margin-bottom: 35px;
}

.logo-landscape.footer{
    fill:#888;
    width: 105px;
    margin-left: 0px;
    margin-bottom: 25px;
}

.logo-landscape.pdf{
    fill:white;
    
    margin-left: 0px;
    margin-top: -3px;
}